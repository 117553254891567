import React from "react"
import OptimizedImage from "../../../utils/optimizedImage"
import { switchbackResolveRichText } from "../../../utils/resolve-rich-text/switchbackResolveRichText"
import { Persona, PersonaHeadingWrap, ImageWrap, PersonaKicker, PersonaHeading, PersonaCopy, PersonaButton } from "../styles/FeaturedPersona.styled"

export const PersonaComponent = ({ persona }) => {
  const {
    kicker,
    heading,
    subheading,
    bodyCopy,
    featuredImage,
    callsToAction,
  } = persona 

  return (
    <Persona>
      <PersonaHeadingWrap>
        {featuredImage && 
          <ImageWrap>
            <OptimizedImage image={featuredImage?.gatsbyImageData} src={featuredImage?.file?.url} alt={featuredImage?.title}/>
          </ImageWrap>
        }
        {kicker && <PersonaKicker>{kicker}</PersonaKicker>}
        {heading && <PersonaHeading>{heading}</PersonaHeading>}
      </PersonaHeadingWrap>
      {subheading && <PersonaCopy>{switchbackResolveRichText(subheading, "persona-check")}</PersonaCopy>}
      {bodyCopy && <PersonaCopy>{switchbackResolveRichText(bodyCopy, "persona-check")}</PersonaCopy>}
      {callsToAction && callsToAction.map(cta => (
        <PersonaButton key={cta?.id} to={cta?.link}>
          {cta?.label}
        </PersonaButton>
      ))}
    </Persona>
  )
}