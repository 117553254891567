import React from "react"
import { PersonaComponent } from "./PersonaComponent"
import { color } from "../../../atoms/colors"
import { FeaturedHeading, HeadingWrapper, FeaturedPersonaWrapper, FeaturedSubheading, PersonaWrapper } from "../styles/FeaturedPersona.styled"

const IconBlockFeaturedPersona = ({ component }) => {
  const { heading, subheading, background, featuredPersona } = component 
  const length = featuredPersona?.length
  let backgroundColor
  if (background === "grey") {
    backgroundColor = color.grey[100]
  } else {
    backgroundColor = color.common.white
  }

  return (
    <FeaturedPersonaWrapper backgroundColor={backgroundColor}>
      <HeadingWrapper>
        <FeaturedHeading>{heading}</FeaturedHeading>
        <FeaturedSubheading>{subheading?.subheading}</FeaturedSubheading>
      </HeadingWrapper>
      <PersonaWrapper length={length}>
        {featuredPersona && featuredPersona.map(persona => (
          <PersonaComponent persona={persona} key={persona?.id} />
        ))}
      </PersonaWrapper>
    </FeaturedPersonaWrapper>
  )
}

export default IconBlockFeaturedPersona
