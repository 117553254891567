import styled from "styled-components"
import { font } from "../../../atoms/typography"
import { color, buttonThemes } from "../../../atoms/colors"
import { Link } from "gatsby"

export const FeaturedPersonaWrapper = styled.div`
  ${({ backgroundColor }) => backgroundColor && `
    background-color: ${backgroundColor};
  `}
  padding: 96px 30px;
  @media (max-width: 992px) {
    padding: 64px 30px;
  }
  @media (max-width: 576px) {
    padding: 48px 30px;
  }
`

export const HeadingWrapper = styled.div`
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 48px;
`

export const FeaturedHeading = styled.h2`
  ${font("display", "lg", "900" )}
  color: ${color.common.black};
  @media (max-width: 992px) {
    ${font("display", "md", "900")}
  }
  @media (max-width: 576px) {
    ${font("display", "sm", "900")}
  }
`

export const FeaturedSubheading = styled.p`
  ${font("text", "reg")}
  color: ${color.grey[600]};
  margin-top: 16px;
`

export const PersonaWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  max-width: 1170px;
  margin: 0 auto;
  ${({ length }) => length && `
    grid-template-columns: repeat(${length}, 1fr);
  `}
  @media (max-width: 992px) {
    max-width: 770px;
    grid-template-columns: 1fr;
  }
  @media (max-width: 576px) {
    max-width: 506px;
  }
`

export const Persona = styled.div`
  background-color: ${color.common.white};
  padding: 24px 24px 40px;
  border: 1px solid ${color.grey[200]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

export const PersonaHeadingWrap = styled.div`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${color.grey[300]};
`

export const ImageWrap = styled.div`
  margin-bottom: 24px;
  img {
    width: 72px;
    height: 72px;
  }
`

export const PersonaKicker = styled.p`
  ${font("kicker", "reg", "900")}
  color: ${color.common.workgridBlue};
  margin-bottom: 24px;
`

export const PersonaHeading = styled.h3`
  ${font("text", "lg", "700")}
  color: ${color.common.black};
`

export const PersonaCopy = styled.div`
  * {
    ${font("text", "reg")}
    color: ${color.grey[600]};
  }
  p {
    margin-bottom: 24px;
  }
`

export const PersonaButton = styled(Link)`
  ${buttonThemes.primary}
  ${font("text", "reg", "700")}
  margin-top: auto;
`